import React from "react";
import mv_main_pic from "../assets/mv.png"
import visual_task_pic_zh from "../assets/visualization_tasks.png"
import visual_task_pic_en from "../assets/visualization_tasks_en.png"
import md_intro_zh from "../content/intro.md";
import md_intro_en from "../content/intro-eng.md";
import useRenderMarkdown from "./hooks/render-md";
import { Typography } from "antd";
import { Layout, Image } from "antd";
const { Title, Paragraph } = Typography;
const { Content } = Layout;

export default function Introduction(props) {
  const md_intro = props.lang === "en" ? md_intro_en : md_intro_zh;
  const visual_task_pic = props.lang === "en" ? visual_task_pic_en : visual_task_pic_zh;
  const isEn = props.lang === "en"
  return (
    <div>
      <Content style={{ padding: "10px 10px 10px 10px" }}>
        <div>
          <Image
            src={mv_main_pic}
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
            preview={false}
          />
          {/* <div style={{ textAlign: "center" }}>{isEn ? "Multiple view visualizations" : "多图表可视化"}</div> */}
        </div>

        <Title level={2}>
          {props.lang === "en" ? "Introduction" : "介绍"}
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Paragraph style={{ fontSize: "16px" }}>
            {useRenderMarkdown(md_intro)}
          </Paragraph>
        </div>
        <div>
          <Image
            src={visual_task_pic}
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
            preview={false}
          />
          <div style={{ textAlign: "center" }}>{isEn ? "Application Scenarios for Chart2Vec" : "Chart2Vec 的应用场景"}</div>
        </div>
      </Content>
    </div>
  );
}
